import React from 'react';
import { withSearchParams } from '../../extensions/withSearchParams';
import { YearPicker } from '../../ui/YearPicker';
import { Form } from 'react-bootstrap';
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";

class CapitalisationReport extends React.Component {  
    constructor (props) {
        super(props); 
        const currentDate = new Date();
        let endMonth = new Date(currentDate.getFullYear(), 1, 1);
        if (currentDate.getMonth() < 2)
          endMonth = new Date(currentDate.getFullYear() - 1, 1, 1);

        

        let areOptionsVisible = true;
        endMonth.setHours(0, -endMonth.getTimezoneOffset(),0,0);

        this.state = {
            endMonth: endMonth,
            teams: [],
            areOptionsVisible: areOptionsVisible
        }        
    }

    fetchData() {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };
    
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const url = process.env.REACT_APP_API_BASE_URL + "/teams/Capitalization";
            const bearer = "Bearer " + tokenResponse.accessToken;            
            fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(response => response.json()).then(result => {
                const allParents = result.map(x => x.teamParentName);
                const unique = [...new Set(allParents)];                        
                this.setState({
                    endMonth: this.state.endMonth,
                    teams: unique,
                    areOptionsVisible: this.state.areOptionsVisible
                })
            })
        })
        .catch(err => {
            console.log(err);
            this.setState({
                endMonth: this.state.endMonth,
                teams: [],
                areOptionsVisible: this.state.areOptionsVisible
            });
        })             
    }

    onYearChange(year) {        
        year.setHours(0, -year.getTimezoneOffset(),0,0);
        this.setState({
            endMonth: year,
            teams: this.state.teams,
            areOptionsVisible: this.state.areOptionsVisible
        })        
    }

    onDownloadClick(selectedTeam) {          
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };
    
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            let url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/CapitalisationReportFile/" + selectedTeam + "?year=" + this.state.endMonth.getFullYear();
        
            const bearer = "Bearer " + tokenResponse.accessToken;            
            fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(response => response.blob())
            .then(result => {
                const url = window.URL.createObjectURL(result);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Capitalization_' + selectedTeam + '_' + this.state.endMonth.getFullYear() + '.xlsx');
                document.body.appendChild(link);
                link.click();            
                })
            })   
            .catch(err => {
                console.log(err);
                this.setState({
                    options: ["API connection failed"]
                });              
            });                
    }    

    componentDidMount() {
        this.setState({
            endMonth: this.state.endMonth,
            teams: this.state.teams,
            areOptionsVisible: this.state.areOptionsVisible
        });
        this.fetchData();    
    }
    
    render() {  

        return (
          <div className="container"> 
          { this.state.areOptionsVisible ?
            (<div>
                <ReportNavigationBar selectedItem={'CapitalisationReport'} team={this.state.selectedTeam} endMonth={this.state.endMonth}/>
            <div className="row">
                <div className="col-sm-4">
                    <Form.Label>Year</Form.Label>
                    <YearPicker value={this.state.endMonth} onChange={(o) => this.onYearChange(o)} />
                </div>
            </div>            
            <div className="row mt-3"></div>            
            {this.state.teams.map(t => (
                <div className="row">
                    <div className="col-sm-8">
                    <a href="#" onClick={(o) => this.onDownloadClick(t)}>Download report for {t} {this.state.endMonth.getFullYear()}</a>
                    </div>
                </div>))}
    
            <div className="row mt-2"></div>
            </div>
            ) : <div></div>}
          </div>   
        )
    }
}

export default withMsal(withSearchParams(CapitalisationReport));




