import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { Pie } from "react-chartjs-2";
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

  class MonthlyDevSummaryRatioPie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          startMonth: props.startMonth,
          endMonth: props.endMonth,
          chartData: null,
          status: ""
        }
      }
    
      fetchData(startMonth, endMonth) {
        const request = {
          ...apiRequest,
          account: this.props.msalContext.accounts[0]
        };
  
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {          
          const bearer = "Bearer " + tokenResponse.accessToken;       
          const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/MonthlyDevOverviewSummary?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();    
          fetch(url, {
            headers: new Headers({
              "X-API-KEY": process.env.REACT_APP_API_KEY
            })
          })
            .then(response => response.json())
            .then(result => {
              let title = "";
              if (startMonth && endMonth)
              {          
                if (startMonth.toISOString() === endMonth.toISOString())
                  title = "Total " 
                  + (startMonth.getMonth() + 1) + "/" + (startMonth.getFullYear());
                else
                  title = "Total " 
                  + (startMonth.getMonth() + 1) + "/" + (startMonth.getFullYear()) + " - "
                  + (endMonth.getMonth() + 1) + "/" + (endMonth.getFullYear());
              }
              result.labels.shift();
              let summaryData = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0];
              result.datasets.forEach(e => {                
                for (let i = 0; i < 8; i++)
                  summaryData[i] += e.data[i];
              });
              const total = summaryData[0];
              summaryData.shift();
              for (let i = 0; i < 7; i++)
                summaryData[i] = (summaryData[i] / total * 100);
                      
              this.setState({
                startMonth: startMonth,
                endMonth: endMonth,
                chartData: {                    
                  title : title,
                  labels: result.labels,
                  datasets: [{
                    label: "Total",
                    data: summaryData,
                    backgroundColor: [
                        "#003856",
                        "#EB6638",
                        "#F18D62",
                        "#DDD7D1",
                        "#FBF7F3",
                        "#fa4b06",
                        "#D76E46"]
                    }]
                },
                status: ''
              });
            })
        })
        .catch(err =>
          {
            console.log(err);
            this.setState({
              startMonth: startMonth,
              endMonth: endMonth,             
              chartData: null,
              status: "Error while loading the pie data"
            });
          })
      }
    
      componentDidMount() {
          this.setState({
            startMonth: this.state.startMonth,
            endMonth: this.state.endMonth,
            chartData: this.state.chartData,
            status: "Loading dev overview summary pie"
          });
          this.fetchData(this.state.startMonth, this.state.endMonth);    
      }
    
      componentDidUpdate(prevProps, prevState) {    
        if (this.props.startMonth !== this.state.startMonth
          || this.props.endMonth !== this.state.endMonth)
          this.fetchData(this.props.startMonth, this.props.endMonth);
      }
    
      render() {          
        if (this.state.chartData) {
          const chartOptions = {
            responsive: true,
            radius: "90%",
            plugins: {
              legend: {
                position: 'bottom',
                display: true,
                labels: {
                    font: {
                        size: 9
                    }
                }
              },
              title: {
                  display: true,
                  text: this.state.chartData.title,
                  color: '#000000',
                  font: {
                  size: 22,
                  }
              },
              datalabels: {
                color: '#000000',
                backgroundColor : '#FFFFFF',
                borderColor: '#000000',
                borderWidth: 1,
                anchor: 'end',
                align: 'center',
                display: 'auto',
                rotation: 0,                
                formatter: function(value, context) {
                  return context.chart.config.data.labels[context.dataIndex] + "\n" + value.toFixed(0) +"%";
                }
              }        
            },
            hover: {
              mode: 'nearest',
              intersect: true    
            }
          };
      
    
          return (      
            <Pie options={chartOptions} data={this.state.chartData}/> 
          )       
        }
        else {
          return (
            <div className="alert alert-warning" role="alert">
                  {this.state.status}
            </div>);
            }
    
      }
    }

    export default withMsal(MonthlyDevSummaryRatioPie);
    