import React from 'react';
import MonthlyDevSummaryMainChart from './MonthlyDevSummaryMainChart';
import MonthlyDevSummaryTable from './MonthlyDevSummaryTable';
import MonthlyDevSummaryRatioPie from './MonthlyDevSummaryRatioPie';
import { withSearchParams } from '../../extensions/withSearchParams';
import { MonthPicker } from "../../ui/MonthPicker";
import { Form } from 'react-bootstrap';
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';

class MonthlyDevSummary extends React.Component {  
    constructor (props) {
        super(props); 
        const currentDate = new Date();
        let endMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endMonth.setMonth(endMonth.getMonth()-1);        
        let startMonth = new Date(endMonth);
        startMonth.setMonth(startMonth.getMonth());

        let areOptionsVisible = true;
        if (this.props.searchParams)
        {            
            startMonth = this.props.searchParams.get("startMonth") ? new Date(this.props.searchParams.get("startMonth")) : startMonth;
            endMonth = this.props.searchParams.get("endMonth") ? new Date(this.props.searchParams.get("endMonth")) : endMonth;                
            if (this.props.searchParams.get("nofilter") === '1')
              areOptionsVisible = false;
        }        
        startMonth.setHours(0, -startMonth.getTimezoneOffset(),0,0);
        endMonth.setHours(0, -endMonth.getTimezoneOffset(),0,0);

        this.state = {
            startMonth: startMonth,
            endMonth: endMonth,
            areMonthsLinked: true,
            areOptionsVisible: areOptionsVisible
        }        
    }


    onStartDateChange(startDate) {        
        startDate.setHours(0, -startDate.getTimezoneOffset(),0,0);
        let endDate = this.state.endMonth;
        if (this.state.areMonthsLinked)
          endDate = startDate;
        this.setState({
            startMonth: startDate <= endDate ? startDate : endDate,
            endMonth: startDate <= endDate ? endDate : startDate,
            areMonthsLinked: this.state.areMonthsLinked,
            areOptionsVisible: this.state.areOptionsVisible
        })        
    }

    onEndDateChange(endDate) {        
        endDate.setHours(0, -endDate.getTimezoneOffset(),0,0);
        let startDate = this.state.startMonth;
        if (this.state.areMonthsLinked)
          startDate = endDate;
        this.setState({
            startMonth: endDate >= startDate ? startDate : endDate,
            endMonth: endDate >= startDate ? endDate : startDate,
            areMonthsLinked: this.state.areMonthsLinked,
            areOptionsVisible: this.state.areOptionsVisible
        })    
    }

    onDateLinkChange() {
        this.setState({
            startMonth: this.state.startMonth,
            endMonth: this.state.endMonth,
            areMonthsLinked: !this.state.areMonthsLinked,
            areOptionsVisible: this.state.areOptionsVisible
        })    
    }
    
    render() {  

        return (
          <div className="container"> 
          { this.state.areOptionsVisible ?
            (<div>
                <ReportNavigationBar selectedItem={'MonthlyDevSummary'} startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>

            <div className="row">
               <div className="col-sm-4">
                    <Form.Label>Start month</Form.Label>
                    <MonthPicker value={this.state.startMonth} onChange={(o) => this.onStartDateChange(o)} />
                </div>
                <div className="col-sm-4">
                    <Form.Label>End month</Form.Label>
                    <MonthPicker value={this.state.endMonth} onChange={(o) => this.onEndDateChange(o)} />
                </div> 
                <div className="col-sm-4">            
                    <Form.Label>Options</Form.Label>        
                    <Form.Check type="checkbox" id="chkLinkDates" label="The dates are connected" checked={this.state.areMonthsLinked} onChange={(o) => this.onDateLinkChange(o)} />                               
                </div>
            </div>            
            <div className="row mt-2"></div>
            </div>
            ) : <div></div>}
            <div className='card'>
            <div className="row">
                <div className="col-sm-8">
                  <MonthlyDevSummaryMainChart startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>
                </div>
                <div className="col-sm-4">
                    <MonthlyDevSummaryRatioPie startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>
                </div>
            </div>
            <div className="row mt-5"></div>
            <div className="row">
               <MonthlyDevSummaryTable startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>
            </div>
          </div>   
          </div>
        )
    }
}

export default withSearchParams(MonthlyDevSummary);




