import React from 'react';
import { apiRequest } from "../authConfig";
import { withMsal } from "@azure/msal-react";

class TeamPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            subset: props.subset
        }
    }

    change = (event) => {
        this.props.onChange(event.target.value);
    }

    componentDidMount() {        
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };
    
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            let url = process.env.REACT_APP_API_BASE_URL + "/teams";
            if (this.state.subset)
              url += "/" + this.state.subset;

            const bearer = "Bearer " + tokenResponse.accessToken;      
            fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(r => { return r.json(); })
            .then(result => {
                const allParents = result.map(x => x.teamParentName);
                const unique = [...new Set(allParents)];
                this.setState({
                    options: unique
                })
            })
        })        
        .catch(err => {
            console.log(err);
            this.setState({
                options: ["API connection failed"]
            });              
        });
    }

    render() {
        return (
            <select className="form-control" onChange={this.change} value={this.props.value}>
                {this.state.options.map(v => {
                    return <option key={v} value={v}>{v}</option>;
                })
                }
            </select>

        )
    }
}

export default withMsal(TeamPicker);