import React from 'react';
import Table from 'react-bootstrap/Table'
import {apiRequest} from "../../authConfig";
import {withMsal} from "@azure/msal-react";

class TableView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTeam: props.selectedTeam,
            isLoading: true,
            jiraMetrics: {
                activeSprintInfo: {
                  sprintName: "",
                  totalIssueCount: 0,
                  doneIssueCount: 0,
                  sumOfDoneStoryPoints: 0
                },
                previousSprintInfo: {
                  sprintName: "",
                  totalIssueCount: 0,
                  doneIssueCount: 0,
                  sumOfDoneStoryPoints: 0
                },
                backlogInfo: {
                  totalBacklogItemCount: 0,
                  refinedBacklogItemCount: 0
                },
                hasError: false,
                errorMessage: "",
                newBugsInTheLast30Days: 0,
                newIncidentsInTheLast30Days: 0
              },
            capitalizationMetrics: {
                capacity: 0,
                capitalization: 0
            },
            sonarMetrics: {
                hasError: false,
                errorMessage: "",
                projectResults: [],                  
                summary: {
                  codeCoverageInPercent: 0,
                  codeLineCount: 0,
                  technicalDebtInHours: 0
                }
              },
            doraMetrics: {
                meanTimeToRecover: 0,
                cycleTime: 0,
                deploymentFrequency: 0
              },
            targetMonth: new Date()                
        }
    }

    localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
    intOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      };



    formatDecimal(number) {
        return number.toLocaleString(undefined, this.localeOptions)
    }  

    formatInt(number) {
        return number.toLocaleString(undefined, this.intOptions)
    }  


    formatPercent(number) {
        return (number * 100).toLocaleString(undefined, this.localeOptions) + '%';
    }  

    formatMonth(date) {
        if (date)
            return (date.toLocaleString('en-US', {month: 'long'}) + " " + date.getFullYear());
        else
           return "";
    }


    fetchJiraMetrics(selectedTeam) {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };        

        return this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const url = process.env.REACT_APP_API_BASE_URL + "/JiraMetrics/JiraTeamInfos/" + selectedTeam;
            const bearer = "Bearer " + tokenResponse.accessToken;            
            return fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(r => { return r.json(); })
            .then(result => {
                return result; 
            })
        })        
        .catch(err => {
            console.log(err);
            return err;
        });
    }

    fetchSonarMetrics(selectedTeam) {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };        

        return this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const url = process.env.REACT_APP_API_BASE_URL + "/SonarMetrics/" + selectedTeam;
            const bearer = "Bearer " + tokenResponse.accessToken;            
            return fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(r => { return r.json(); })
            .then(result => {
                return result; 
            })
        })        
        .catch(err => {
            console.log(err);
            return err;
        });
    }    

    fetchCapitalizationMetrics(selectedTeam) {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };        

        return this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const date = new Date();
            const startMonth = new Date(date.getFullYear() - (date.getMonth() ? 0 : 1), (date.getMonth() + 11) % 12, 2);
            const endMonth = startMonth;

            const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/MonthlyDevOverview/" + selectedTeam + "?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();    ;
            const bearer = "Bearer " + tokenResponse.accessToken;            
            return fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(r => { return r.json(); })
            .then(result => {
                return {
                  capacity: result.datasets[0].data[0][0],
                  capitalization: result.datasets[0].data[1][0] - result.datasets[0].data[1][1]
                }
            })
        })        
        .catch(err => {
            console.log(err);
            return err;
        });
    }

    fetchDoraMetrics(selectedTeam) {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };        

        return this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const date = new Date();
            const startMonth = new Date(date.getFullYear() - (date.getMonth() ? 0 : 1), (date.getMonth() + 11) % 12, 2);
            const daysInMonth = new Date(startMonth.getFullYear(), startMonth.getMonth()+1, 0).getDate();

            const url = process.env.REACT_APP_API_BASE_URL + "/DoraMetrics/" + selectedTeam + "/- Summary -?targetMonth=" + startMonth.toISOString(); 
            const bearer = "Bearer " + tokenResponse.accessToken;            
            return fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(r => { return r.json(); })
            .then(result => {
                return {
                  meanTimeToRecover: (result.repairs.counter !== 0 ? (result.repairs.duration / result.repairs.counter) : 0),
                  cycleTime: (result.changes.counter !== 0 ? (result.changes.duration / result.changes.counter) : 0),
                  deploymentFrequency: result.deployments.counter / daysInMonth
                }
            })
        })        
        .catch(err => {
            console.log(err);
            return err;
        });
    }    

    fetchData(selectedTeam) {

        Promise.all([this.fetchCapitalizationMetrics(selectedTeam), 
            this.fetchJiraMetrics(selectedTeam),
            this.fetchSonarMetrics(selectedTeam),
            this.fetchDoraMetrics(selectedTeam)])
            .then(result => {
                const [capitalization, jira, sonar, dora] = result;

                const date = new Date();
                const targetMonth = new Date(date.getFullYear() - (date.getMonth() ? 0 : 1), (date.getMonth() + 11) % 12, 2);
    

                this.setState({
                    selectedTeam:selectedTeam,
                    isLoading: false,
                    jiraMetrics: jira,
                    capitalizationMetrics: capitalization,
                    sonarMetrics: sonar,
                    doraMetrics: dora,
                    targetMonth: targetMonth
                });
        });
    }


    componentDidMount() {
        this.setState({
            selectedTeam:this.state.selectedTeam,
            isLoading: true,
            jiraMetrics: this.state.jiraMetrics,
            capitalizationMetrics: this.state.capitalization,
            sonarMetrics: this.state.sonarMetrics,
            doraMetrics: this.state.doraMetrics,
            targetMonth: this.state.targetMonth
        });        
        this.fetchData(this.state.selectedTeam);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedTeam !== this.state.selectedTeam)
        {
            if (!this.state.isLoading) {
                this.setState({
                    selectedTeam:this.state.selectedTeam,
                    isLoading: true,
                    jiraMetrics: this.state.jira,
                    capitalization: this.state.capitalization,
                    sonarMetrics: this.state.sonarMetrics,
                    doraMetrics: this.state.doraMetrics,
                    targetMonth: this.state.targetMonth
                });
            }
            this.fetchData(this.props.selectedTeam);
        }
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <div>
                    <h4 className="text-center">Tactical Engineering Dashboard for {this.state.selectedTeam}</h4>                
                    <div className="table-roadmap, text-left">                    
                    <Table striped bordered hover>
                        <thead>
                            <th className='col-sm-6'>Metric</th>
                            <th className='col-sm-2, col-text-right'>Active Sprint</th>
                            <th className='col-sm-2, col-text-right'>Previous Sprint</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Sprintname</td>
                            <td className="col-text-right">{this.state.jiraMetrics.activeSprintInfo.sprintName}</td>
                            <td className="col-text-right">{this.state.jiraMetrics.previousSprintInfo.sprintName}</td>
                        </tr>                            
                        <tr>
                            <td>Sprint Commitment vs. Completion Rate (%)<br/>(Number of done tasks / Total number of tasks in the sprint [Jira])</td>
                            <td className="col-text-right">{this.formatPercent(this.state.jiraMetrics.activeSprintInfo.doneIssueCount / this.state.jiraMetrics.activeSprintInfo.totalIssueCount)}</td>
                            <td className="col-text-right">{this.formatPercent(this.state.jiraMetrics.previousSprintInfo.doneIssueCount / this.state.jiraMetrics.previousSprintInfo.totalIssueCount)}</td>
                        </tr>
                        <tr>
                            <td>Velocity (SP)<br/>(Sum of story points of done tasks [Jira])</td>
                            <td className="col-text-right">{this.formatInt(this.state.jiraMetrics.activeSprintInfo.sumOfDoneStoryPoints)}</td>
                            <td className="col-text-right">{this.formatInt(this.state.jiraMetrics.previousSprintInfo.sumOfDoneStoryPoints)}</td>
                        </tr>

                        </tbody>
                    </Table>
                    <Table striped bordered hover>
                        <thead>
                            <th className='col-sm-6'>Metric</th>
                            <th className='col-sm-2, col-text-right'>Value</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Product Backlog Health (%)<br/>(Percentage of refined issues in backlog [Jira])</td>
                            <td className="col-text-right">{this.formatPercent(this.state.jiraMetrics.backlogInfo.refinedBacklogItemCount / this.state.jiraMetrics.backlogInfo.totalBacklogItemCount)}</td>    
                        </tr>
                        <tr>
                            <td>Capitalization<br/>(Capitalization for {this.formatMonth(this.state.targetMonth)} [DevReporting - DevOverview])</td>
                            <td className="col-text-right">{this.formatPercent(this.state.capitalizationMetrics.capitalization / this.state.capitalizationMetrics.capacity)}</td>    
                        </tr>
                        <tr>
                            <td>Bug Rate (%) per kLOC<br/>(Number of bugs of last 30 days ({this.state.jiraMetrics.newBugsInTheLast30Days}) / Number of lines of code/1000 (cloc:{this.state.sonarMetrics.summary.codeLineCount} [Jira/Sonarqube])</td>
                            <td className="col-text-right">{this.formatPercent(this.state.jiraMetrics.newBugsInTheLast30Days / (this.state.sonarMetrics.summary.codeLineCount/1000) )}</td>    
                        </tr>
                        <tr>
                            <td>Incidents (#)<br/>(Number of incidents reported in the "Myneva incidents" project in the last 30 days [Jira/Confluence])</td>
                            <td className="col-text-right">{this.formatInt(this.state.jiraMetrics.newIncidentsInTheLast30Days)}</td>    
                        </tr>
                        <tr>
                            <td>Coverage (%)<br/>(Average code coverage of repositories - weighted by lines of code [Sonarqube])</td>
                            <td className="col-text-right">{this.formatPercent(this.state.sonarMetrics.summary.codeCoverageInPercent/100.0)}</td>    
                        </tr>
                        <tr>
                            <td>Technical debt (h)<br/>(Sum of technical debt of all repositories [Sonarqube])</td>
                            <td className="col-text-right">{this.formatDecimal(this.state.sonarMetrics.summary.technicalDebtInHours)}</td>    
                        </tr>
                        <tr>
                            <td>MTTR<br/>(Mean time to recovery for {this.formatMonth(this.state.targetMonth)} [DORA event tracking service])</td>
                            <td className="col-text-right">{this.formatDecimal(this.state.doraMetrics.meanTimeToRecover)}</td>    
                        </tr>
                        <tr>
                            <td>Vulnerabilities (#)<br/>(Sum of vulnerabilities of repositories [SNYK])</td>
                            <td className="col-text-right">[Please calculate manually (for now)]</td>    
                        </tr>
                        <tr>
                            <td>Cycle Time (h)<br/>(Lead time for change for {this.formatMonth(this.state.targetMonth)} [DORA event tracking service])</td>
                            <td className="col-text-right">{this.formatDecimal(this.state.doraMetrics.cycleTime)}</td>    
                        </tr>
                        <tr>
                            <td>Deployment Frequency<br/>(Number of deployments in {this.formatMonth(this.state.targetMonth)} divided by number of days [DORA event tracking service])</td>
                            <td className="col-text-right">{this.formatDecimal(this.state.doraMetrics.deploymentFrequency)}</td>    
                        </tr>
                        <tr>
                            <td>Team motivation (1-10)</td>
                            <td className="col-text-right">[Please calculate manually]</td>    
                        </tr>
                        <tr>
                            <td>Stakeholder satisfaction (1-10)</td>
                            <td className="col-text-right">[Please calculate manually]</td>    
                        </tr>
                        </tbody>
                    </Table>                    
                </div>    
                
                </div>);
        } else {
            return (
                <div>
                    <div className="row mt-3"></div>
                    <center><h4>Loading data - getting real time data from Jira can take a while</h4></center>
                    <div class="loading">Loading&#8230;</div>
                </div>);

        }
    }
}

export default withMsal(TableView);