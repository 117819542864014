import React from 'react';
import Table from 'react-bootstrap/Table'
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";
import { CSVLink } from "react-csv";

class MonthlyEffortBreakdownIssueList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team: props.team,
            month: props.month,
            issueList: null,
            sortField: 'timeSpentInHours',
            isSortDesc: true,
            status: ""
        }
    }

    localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
    
    formatNumber(number) {
        return number.toLocaleString(undefined, this.localeOptions)
    }  
    

    fetchData(team, month) {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };
    
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/MonthlyEffortBreakdown/" + team + "/Issues?month=" + month.toISOString()
            const bearer = "Bearer " + tokenResponse.accessToken;            
            fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(response => response.json()).then(result => {
                this.setState({
                    team: team,
                    month: month,
                    issueList: result.issueData,
                    sortField: this.state.sortField,
                    isSortDesc: this.state.isSortDesc,
                    status: ""
                })
            })
        })
        .catch(err => {
            console.log(err);
            this.setState({
                team: team,
                month: month,
                issueList: null,
                sortField: this.state.sortField,
                isSortDesc: this.state.isSortDesc,
                status: "Error while loading the issue data"
            });
        })        
    }

    componentDidMount() {
        this.setState({
            team: this.state.team,
            month: this.state.month,
            issueList: this.state.issueList,
            sortField: this.state.sortField,
            isSortDesc: this.state.isSortDesc,
            status: "Loading issue detail list"
        });
        this.fetchData(this.state.team, this.state.month);        
    }

    componentDidUpdate(prevProps, prevState) {        
        if (this.props.team !== this.state.team || this.props.month !== this.state.month)
            this.fetchData(this.props.team, this.props.month);
    }

    setSortedField(newSortField) {
        const isSortDesc = newSortField === this.state.sortField ? !this.state.isSortDesc : false;
        this.setState({
            team: this.state.team,
            month: this.state.month,
            issueList: this.state.issueList,
            sortField: newSortField,
            isSortDesc: isSortDesc,
            status: this.state.status

        })
    }


    render() {
        if (this.state.issueList) {
            let displayList = [];
            this.state.issueList.forEach(i => {
                displayList.push(
                    {
                        issueKey: i.issueKey,
                        issueSummary: i.issueSummary,
                        timeSpentInHoursFormatted: this.formatNumber(i.timeSpentInHours),                        
                        timeSpentInHours: i.timeSpentInHours,
                        categoryName: i.categoryName,
                        reason: i.reason
                    }
                )                
            });


            displayList.sort((a,b) => {
                if (a[this.state.sortField] < b[this.state.sortField]) {
                    return this.state.isSortDesc ? 1 : -1;
                } else if (a[this.state.sortField] > b[this.state.sortField]) {
                    return this.state.isSortDesc ? -1 : 1;
                } else {
                    return 0;
                }                  
            });

            
            const sortSymbol = this.state.isSortDesc 
              ? " 🠣"
              : " 🠡";

            return (
                <div>
                <h4 className="text-center">Issue categorization {this.state.team} {this.state.month.getMonth()+1}/{this.state.month.getFullYear()}</h4>
                
                <CSVLink data={displayList} separator={";"} enclosingCharacter={'"'} filename={"effort_breakdown_" + this.state.team + ".csv"}>Download data as csv</CSVLink>
                
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('issueKey')}>Key{this.state.sortField === "issueKey" && sortSymbol}</button></th>
                            <th className='col-sm-5'><button className="btn btn-primary" onClick={() => this.setSortedField('issueSummary')}>Summary{this.state.sortField === "issueSummary" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('timeSpentInHours')}>Time spent{this.state.sortField === "timeSpentInHours" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('categoryName')}>Category{this.state.sortField === "categoryName" && sortSymbol}</button></th>
                            <th className='col-sm-4'><button className="btn btn-primary" onClick={() => this.setSortedField('reason')}>Reason{this.state.sortField === "reason" && sortSymbol}</button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayList.map(i => (
                            <tr key={i.ticketDataId}>
                                <td><a href={i.issueLink} className="link-dark" target="_blank" rel="noreferrer">{i.issueKey}</a></td>
                                <td>{i.issueSummary}</td>
                                <td className="col-text-right">{i.timeSpentInHoursFormatted}</td>
                                <td>{i.categoryName}</td>
                                <td>{i.reason}</td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
                </div>
            )
        } else {
            return (
                <div className="alert alert-warning" role="alert">
                      {this.state.status}
                </div>);
        
        }
    }
}

export default withMsal(MonthlyEffortBreakdownIssueList);