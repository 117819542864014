import React from 'react';
import Table from 'react-bootstrap/Table'
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";
import { ColorBlock } from '../../ui/ColorBlock.js';

class AdministrativeTaskBreakdownTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team: props.team,
            startMonth: props.startMonth,
            endMonth: props.endMonth,
            tableData: null,
            status: ""
          }
    }

    localeOptions = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };
  
    formatNumber(number) {
      return number.toLocaleString(undefined, this.localeOptions)
    }  
      

    fetchData(team, startMonth, endMonth) {
        const request = {
          ...apiRequest,
          account: this.props.msalContext.accounts[0]
        };
  
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {          
          const bearer = "Bearer " + tokenResponse.accessToken;       
          const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/AdministrativeTaskBreakdown/" + team + "?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();    
          fetch(url, {
            headers: new Headers({
              "X-API-KEY": process.env.REACT_APP_API_KEY
            })
          })
            .then(response => response.json())
            .then(result => {
              

              this.setState({
                team: team,
                startMonth: startMonth,
                endMonth: endMonth,
                tableData: result.taskBreakdowns,
                status: ''
              });
            })
        })
        .catch(err =>
          {
            console.log(err);
            this.setState({
              team: team,
              startMonth: startMonth,
              endMonth: endMonth,             
              tableData: null,
              status: "Error while loading the table data"
            });
          })
      }

      componentDidMount() {
        this.setState({
          team: this.state.team,
          startMonth: this.state.startMonth,
          endMonth: this.state.endMonth,
          tableData: this.state.tableData,
          status: "Loading administrative task breakdown table"
        });
        this.fetchData(this.state.startMonth, this.state.endMonth);    
    }

    componentDidUpdate(prevProps, prevState) {    
        if (this.props.startMonth !== this.state.startMonth
          || this.props.endMonth !== this.state.endMonth
          || this.props.team !== this.state.team)
          this.fetchData(this.props.team, this.props.startMonth, this.props.endMonth);
      }


    render() {
        if (this.state.tableData) {
            return (
                <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className='col-sm-2'>Task</th>
                            <th className='col-sm-1 col-text-right'>Total</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.tableData.map(i => (
                            <tr key={i.team}>
                                <td>{i.issueKey + " " + i.name}</td>
                                <td className="col-text-right">{this.formatNumber(i.timeSpent)}</td>
                                <td className="col-text-right col-text-italic ">{this.formatNumber(i.timeSpentInPercent)}%</td>
                            </tr>
                        ))}                        
                    </tbody>
                </Table>
                </div>
            )
        } else {
            return (
                <div className="alert alert-warning" role="alert">
                      {this.state.status}
                </div>);
        
        }
    }
}

export default withMsal(AdministrativeTaskBreakdownTable);