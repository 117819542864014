import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);



class MonthlyDoraMetricsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTeam: props.selectedTeam,      
      selectedResource: props.selectedResource,
      startMonth: props.startMonth,
      endMonth: props.startMonth,
      chartData: null,
      status: ''
    }
  }

  localeOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  formatNumber(number) {
    return number.toLocaleString(undefined, this.localeOptions)
  }  


  fetchData(selectedTeam, selectedResource, startMonth, endMonth) {
    const request = {
      ...apiRequest,
      account: this.props.msalContext.accounts[0]
    };

    this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {    
      startMonth.setHours(0, -startMonth.getTimezoneOffset(),0,0);
      endMonth.setHours(0, -endMonth.getTimezoneOffset(),0,0);
      const url = process.env.REACT_APP_API_BASE_URL + "/DoraMetrics/MonthlyResults/" + selectedTeam + "/" + selectedResource + "?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();
      const bearer = "Bearer " + tokenResponse.accessToken;            
      fetch(url, {
          headers: new Headers({
            "X-API-KEY": process.env.REACT_APP_API_KEY
          })
      })
        .then(response => response.json())
        .then(result => {
          let title = "";
          if (startMonth && endMonth)
          {          
            title = "Dora metrics chart for " + selectedTeam + " / " + selectedResource + " " 
              + (startMonth.getMonth() + 1) + "/" + (startMonth.getFullYear()) + " - "
              + (endMonth.getMonth() + 1) + "/" + (endMonth.getFullYear());
          }

          let chartdata = {
            datasets: [
                {
                    "label" : "Deployment frequency",
                    "data" : [],
                    "borderColor": "#00B050",
                    "backgroundColor": "#00B050",
                    "fill": false,
                    "borderWidth": 2,
                    "lineTension": 0.4,
                    "borderDash": []                    
                },
                {
                    "label": "Lead time for changes",
                    "data": [],
                    "borderColor": "#002060",
                    "backgroundColor": "#002060",
                    "fill": false,
                    "borderWidth": 2,
                    "lineTension": 0.4,
                    "borderDash": []
                }, 
                {
                    "label": "Mean time to recover",
                    "data": [],
                    "borderColor": "#F1DFCD",
                    "backgroundColor": "#F1DFCD",
                    "fill": false,
                    "borderWidth": 2,
                    "lineTension": 0.4,
                    "borderDash": [
                      10,
                      5
                    ]
                }, 
                {
                    "label": "Change failure rate",
                    "data": [],
                    "borderColor": "#FF0000",
                    "backgroundColor": "#FF0000",
                    "fill": false,
                    "borderWidth": 2,
                    "lineTension": 0.4,
                    "borderDash": []
                }                                              

            ],
            labels:[]
          };

          result.periods.forEach(p => {
            let date = new Date(new Date(p.targetDate));

            chartdata.labels.push((date.getMonth() + 1) + "/" + date.getFullYear());
            chartdata.datasets[0].data.push(p.deployments.counter);

            if (p.changes.counter > 0)
              chartdata.datasets[1].data.push(p.changes.duration / p.changes.counter);
            else
              chartdata.datasets[1].data.push(0);

            if (p.repairs.counter > 0)            
              chartdata.datasets[2].data.push(p.repairs.duration / p.repairs.counter);
            else
              chartdata.datasets[2].data.push(0);

            if (p.deployments.counter > 0)
              chartdata.datasets[3].data.push(p.deployments.repair / p.deployments.counter);
            else
              chartdata.datasets[3].data.push(0);
          });
        

          this.setState({
            startMonth: startMonth,
            endMonth: endMonth,
            selectedTeam: selectedTeam,
            selectedResource: selectedResource,
            chartData: {
              title : title,
              datasets: chartdata,
            },
            status: ''
          });
        })
    })
    .catch(err =>
      {
        console.log(err);
        this.setState({
          startMonth: startMonth,
          endMonth: endMonth,
          selectedTeam: selectedTeam,
          chartData: null,
          status: "Error while loading the chart data"
        });
      })
  }

  componentDidMount() {    
      this.setState({
        selectedTeam: this.state.selectedTeam,
        selectedResource: this.state.selectedResource,
        startMonth: this.state.startMonth,
        endMonth: this.state.endMonth,
        chartData: this.state.chartData,
        status: "Loading dora metric chart"
      });
  }

  componentDidUpdate(prevProps, prevState) {    
    if (this.props.selectedTeam !== this.state.selectedTeam
      || this.props.startMonth !== this.state.startMonth
      || this.props.endMonth !== this.state.endMonth
      || this.props.selectedResource !== this.state.selectedResource)
      this.fetchData(this.props.selectedTeam, this.props.selectedResource, this.props.startMonth, this.props.endMonth);
  }

  render() {  
    if (this.state.chartData) {
      const chartOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: true
          },
          title: {
            display: true,
            text: this.state.chartData.title,
            color: '#000000',
            font: {
              size: 22,
            }
          },
          datalabels: {
            display: false
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        }
      };

      return (      
        <div>
          <Line options={chartOptions} data={this.state.chartData.datasets} />         
        </div>                  
      )       
    }
    else {
      return (
        <div className="alert alert-warning" role="alert">
              {this.state.status}
        </div>);
    }

  }
}

export default withMsal(MonthlyDoraMetricsChart);
