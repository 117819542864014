import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useSearchParams } from "react-router-dom";
import Button from "react-bootstrap/Button";


let msalInstance;
function handleLogin(instance, doUsePopup) {

    if (doUsePopup)
    {
        instance.loginPopup(loginRequest).then(e => instance.devReportingToken = e.accessToken).catch(e => {
            console.error(e);
        });
    }
    else
    {
        msalInstance = instance;
        instance.handleRedirectPromise().then(handleLoginResponse).catch(e => {
            console.error(e);
        });     
    }
}

function handleLoginResponse(response) {
    let accountId = "";
    if (response !== null) {
        accountId = response.account.homeAccountId;
        // Display signed-in user content, call API, etc.
    } else {
        // In case multiple accounts exist, you can select
        const currentAccounts = msalInstance.getAllAccounts();

        if (currentAccounts.length === 0) {
            // no accounts signed-in, attempt to sign a user in
            msalInstance.loginRedirect(loginRequest);
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            accountId = currentAccounts[0].homeAccountId;
        }
    }
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const searchParams = useSearchParams();
    const doUsePopup = searchParams && searchParams[0].get("usepopuplogin") === '1';

    return (
        <Button variant="secondary" className="ml-auto" onClick={() => handleLogin(instance, doUsePopup)}>Sign in with myneva account</Button>
    );
}