import React from 'react';
import Table from 'react-bootstrap/Table'
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";
import { ColorBlock } from '../../ui/ColorBlock.js';

class MonthlyDevSummaryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startMonth: props.startMonth,
            endMonth: props.endMonth,
            tableData: null,
            status: ""
          }
    }

    fetchData(startMonth, endMonth) {
        const request = {
          ...apiRequest,
          account: this.props.msalContext.accounts[0]
        };
  
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {          
          const bearer = "Bearer " + tokenResponse.accessToken;       
          const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/MonthlyDevOverviewSummary?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();    
          fetch(url, {
            headers: new Headers({
              "X-API-KEY": process.env.REACT_APP_API_KEY
            })
          })
            .then(response => response.json())
            .then(result => {
              let summaryRow = {
                  capitalizable: 0.0,
                  noncapitalizable: 0.0,
                  billable: 0.0,
                  bugfixing: 0.0,
                  nonbillable: 0.0,
                  fieldSupport: 0.0,
                  admin: 0.0,
                  capacity: 0.0
              };
              let rows = [];

              result.datasets.forEach(e => {
                  const row = {
                    team: e.label,
                    color: e.backgroundColor[0],
                    capitalizable: e.data[1],
                    noncapitalizable: e.data[2],
                    billable: e.data[4],
                    bugfixing: e.data[3],
                    nonbillable: e.data[5],
                    fieldSupport: e.data[6],
                    admin: e.data[7],
                    capacity: e.data[0]
                };
                summaryRow.capitalizable += row.capitalizable;
                summaryRow.noncapitalizable += row.noncapitalizable;
                summaryRow.billable += row.billable;
                summaryRow.bugfixing += row.bugfixing;
                summaryRow.nonbillable += row.nonbillable;
                summaryRow.fieldSupport += row.fieldSupport;
                summaryRow.admin += row.admin;
                summaryRow.capacity += row.capacity;
                rows.push(row);
              });



              this.setState({
                startMonth: startMonth,
                endMonth: endMonth,
                tableData: {
                    teamData: rows,
                    summary: summaryRow
                },
                status: ''
              });
            })
        })
        .catch(err =>
          {
            console.log(err);
            this.setState({
              startMonth: startMonth,
              endMonth: endMonth,             
              tableData: null,
              status: "Error while loading the table data"
            });
          })
      }

      componentDidMount() {
        this.setState({
          startMonth: this.state.startMonth,
          endMonth: this.state.endMonth,
          tableData: this.state.tableData,
          status: "Loading dev overview summary table"
        });
        this.fetchData(this.state.startMonth, this.state.endMonth);    
    }

    componentDidUpdate(prevProps, prevState) {    
        if (this.props.startMonth !== this.state.startMonth
          || this.props.endMonth !== this.state.endMonth)
          this.fetchData(this.props.startMonth, this.props.endMonth);
      }


    render() {
        if (this.state.tableData) {
            return (
                <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className='col-sm-2'>Product</th>
                            <th className='col-sm-1'>Cap. own prod.</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                            <th className='col-sm-1'>Non cap. own prod.</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                            <th className='col-sm-1'>Billable cust.</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                            <th className='col-sm-1'>Bugfixing</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                            <th className='col-sm-1'>Non-billable</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                            <th className='col-sm-1'>Field support</th>
                            <th className='col-sm-1 col-text-right'>%</th>
                            <th className='col-sm-1'>Admin</th>
                            <th className='col-sm-1 col-text-right'>%</th> 
                            <th className='col-sm-1'>Total</th>                           
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.tableData.teamData.map(i => (
                            <tr key={i.team}>
                                <td><ColorBlock color={i.color} /> {i.team}</td>
                                <td className="col-text-right">{i.capitalizable.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic ">{(i.capitalizable/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right col-background-light">{i.noncapitalizable.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic col-background-light">{(i.noncapitalizable/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right">{i.billable.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic">{(i.billable/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right col-background-light">{i.bugfixing.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic col-background-light">{(i.bugfixing/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right">{i.nonbillable.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic">{(i.nonbillable/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right col-background-light">{i.fieldSupport.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic col-background-light">{(i.fieldSupport/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right col-background-light">{i.admin.toFixed(0)}</td>
                                <td className="col-text-right col-text-italic col-background-light">{(i.admin/i.capacity*100).toFixed(0)}%</td>
                                <td className="col-text-right col-text-bold">{i.capacity.toFixed(0)}</td>
                            </tr>
                        ))}                        
                    </tbody>
                    <tfoot>
                        <tr>
                                <th>Total</th>
                                <th className="col-text-right">{this.state.tableData.summary.capitalizable.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic">{(this.state.tableData.summary.capitalizable/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right col-background-light">{this.state.tableData.summary.noncapitalizable.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic col-background-light">{(this.state.tableData.summary.noncapitalizable/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right">{this.state.tableData.summary.billable.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic">{(this.state.tableData.summary.billable/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right col-background-light">{this.state.tableData.summary.bugfixing.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic col-background-light">{(this.state.tableData.summary.bugfixing/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right">{this.state.tableData.summary.nonbillable.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic">{(this.state.tableData.summary.nonbillable/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right col-background-light">{this.state.tableData.summary.fieldSupport.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic col-background-light">{(this.state.tableData.summary.fieldSupport/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right col-background-light">{this.state.tableData.summary.admin.toFixed(0)}</th>
                                <th className="col-text-right col-text-italic col-background-light">{(this.state.tableData.summary.admin/this.state.tableData.summary.capacity*100).toFixed(0)}%</th>
                                <th className="col-text-right">{this.state.tableData.summary.capacity.toFixed(0)}</th>
                            </tr>                        
                    </tfoot>
                </Table>
                </div>
            )
        } else {
            return (
                <div className="alert alert-warning" role="alert">
                      {this.state.status}
                </div>);
        
        }
    }
}

export default withMsal(MonthlyDevSummaryTable);