import React from 'react';
import DoraNamespacePicker from '../../ui/DoraNamespacePicker';
import DoraRessourcePicker from '../../ui/DoraRessourcePicker';
import { MonthPicker } from "../../ui/MonthPicker";
import { Form } from 'react-bootstrap';
import {withMsal} from "@azure/msal-react";
import {withSearchParams} from "../../extensions/withSearchParams";
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';
import TableView from './TableView';

class TacticalDashboard extends React.Component {
    constructor (props) {
        super(props);
        const currentDate = new Date();
        let month = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let selectedTeam = "myneva.carecenter";

        let areOptionsVisible = true;
        if (this.props.searchParams)
        {
            month = this.props.searchParams.get("month") ? new Date(this.props.searchParams.get("month")) : month;
            selectedTeam = this.props.searchParams.get("team") || selectedTeam;
            if (this.props.searchParams.get("nofilter") === '1')
                areOptionsVisible = false;
        }
        month.setHours(0, -month.getTimezoneOffset(),0,0);

        this.state = {
            month: month,
            selectedTeam: selectedTeam,
            areOptionsVisible: areOptionsVisible
        }
    }

    onTeamChange(option) {
        this.setState({
            month: this.state.month,
            selectedTeam: option,
            areOptionsVisible: this.state.areOptionsVisible
        })
    };

    onResourceChange(option) {
        this.setState({
            month: this.state.month,
            selectedTeam: this.state.selectedTeam,
            areOptionsVisible: this.state.areOptionsVisible
        })
    };

    onStartDateChange(startDate) {
        startDate.setHours(0, -startDate.getTimezoneOffset(),0,0);
        this.setState({
            month: startDate,
            selectedTeam: this.state.selectedTeam,
            areOptionsVisible: this.state.areOptionsVisible
        })
    }

    render() {
        return (
            <div className="container">
                { this.state.areOptionsVisible ?
                    (<div>
                        <ReportNavigationBar selectedItem={'TacticalDashboard'} startMonth={this.state.month} endMonth={this.state.month}/>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label>Product</Form.Label>
                                    <DoraNamespacePicker onChange={(o) => this.onTeamChange(o)} value={this.state.selectedTeam}/>
                                </div>
                            </div>
                            <div className="row mt-2"></div>
                            <TableView selectedTeam={this.state.selectedTeam}/>
                        </div>
                    ) : <div></div>}
            </div>
        )
    }
}

export default withMsal(withSearchParams(TacticalDashboard));