import React from 'react';
import Table from 'react-bootstrap/Table'
import {apiRequest} from "../../authConfig";
import {withMsal} from "@azure/msal-react";

class MonthlyDoraMetricsDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTeam: props.selectedTeam,
            month: props.month,
            selectedResource: props.selectedResource,
            data: {
                deployments: {
                    counter: 0,
                    repair: 0
                },
                changes: {
                    counter: 0,
                    duration: 0,
                    items: ""
                },
                repairs: {
                    counter: 0,
                    duration: 0,
                    items: ""
                }
            },
        }
    }

    localeOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };

    formatDecimal(number) {
        return number.toLocaleString(undefined, this.localeOptions)
    }  

    async fetchData(selectedTeam, selectedResource, month) {

        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };        

        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const url = process.env.REACT_APP_API_BASE_URL + "/DoraMetrics/" + selectedTeam + "/" + selectedResource + "?targetMonth=" + month.toISOString();
            const bearer = "Bearer " + tokenResponse.accessToken;            
            fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(r => { return r.json(); })
            .then(result => {
                this.setState({                
                    selectedTeam:selectedTeam,
                    month: month,
                    selectedResource: selectedResource,
                    data: result                    
                }); 
            })
        })        
        .catch(err => {
            console.log(err);
            this.setState({
                options: ["API connection failed"]
            });              
        });
    }

    componentDidMount() {
        this.setState({
            selectedTeam: this.state.selectedTeam,
            month: this.state.month,
            selectedResource: this.state.selectedResource,
            status: "Loading Data",
            data: this.state.data
        });
        this.fetchData(this.state.selectedTeam, this.state.selectedResource, this.state.month);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedTeam !== this.state.selectedTeam || this.props.selectedResource !== this.state.selectedResource || this.props.month !== this.state.month)
            this.fetchData(this.props.selectedTeam, this.props.selectedResource, this.props.month);
    }

    render() {
        if (this.state.data) {
            return (
                <div>
                    <h4 className="text-center">Dora Metrics for {this.state.selectedTeam}/{this.state.selectedResource}</h4>

                    <div className="table-roadmap, text-left">                    
                    <Table striped bordered hover>
                        <thead>
                            <th className='col-sm-6'>Metric</th>
                            <th className='col-sm-2, col-text-right'>Value</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Deployment frequency</td>
                            <td className="col-text-right">{this.state.data.deployments.counter}</td>
                        </tr>
                        <tr>
                            <td>Lead time for changes</td>
                            <td className="col-text-right">{this.state.data.changes.counter !== 0 ? this.formatDecimal(this.state.data.changes.duration / this.state.data.changes.counter) : 0} h</td>
                        </tr>
                        <tr>
                            <td>Mean time to recover</td>
                            <td className="col-text-right">{this.state.data.repairs.counter !== 0 ? this.formatDecimal(this.state.data.repairs.duration / this.state.data.repairs.counter) : 0} h</td>
                        </tr>
                        <tr>
                            <td>Change failure rate</td>
                            <td className="col-text-right">{this.state.data.deployments.counter !== 0 ? this.formatDecimal(this.state.data.deployments.repair / this.state.data.deployments.counter) : 0}</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>    

                <div>
                    <h5 className="text-center">This is based on the following counter data:</h5>                                
                
                <div className="table-roadmap, text-left">                    
                    <Table striped bordered hover>
                        <thead>
                            <th className='col-sm-6'>Counter</th>
                            <th className='col-sm-2, col-text-right'>Value</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Deployment: counter</td>
                            <td className="col-text-right">{this.state.data.deployments.counter}</td>
                        </tr>
                        <tr>
                            <td>Deployment: repair</td>
                            <td className="col-text-right">{this.state.data.deployments.repair}</td>
                        </tr>
                        <tr>
                            <td>Changes: counter</td>
                            <td className="col-text-right">{this.state.data.changes.counter}</td>
                        </tr>
                        <tr>
                            <td>Changes: duration</td>
                            <td className="col-text-right">{this.state.data.changes.duration}</td>
                        </tr>
                        <tr>
                            <td>Changes: items</td>
                            <td className="col-text-right">{this.state.data.changes.items}</td>
                        </tr>
                        <tr>
                            <td>Repairs: counter</td>
                            <td className="col-text-right">{this.state.data.repairs.counter}</td>
                        </tr>
                        <tr>
                            <td>Repairs: duration</td>
                            <td className="col-text-right">{this.state.data.repairs.duration}</td>
                        </tr>
                        <tr>
                            <td>Repairs: items</td>
                            <td className="col-text-right">{this.state.data.repairs.items}</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                </div>
                </div>);
        } else {
            return (
                <div className="alert alert-warning" role="alert">
                    {this.state.status}
                </div>);
        }
    }
}

export default withMsal(MonthlyDoraMetricsDashboard);