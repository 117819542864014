import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import mynevaLogo from "../assets/myneva.svg";

export function ReportNavigationBar(props) {

    const [selectedItem, setSelectedItem] = useState('');
    const [team, setSelectedTeam] = useState('');
    const [startMonth, setSelectedStartMonth] = useState('');
    const [endMonth, setSelectedEndMonth] = useState('');

    useEffect(() => {      
      setSelectedItem(props.selectedItem);
      setSelectedTeam(props.team);      
      setSelectedStartMonth(props.startMonth);      
      setSelectedEndMonth(props.endMonth);
    }, [props.selectedItem, props.team, props.startMonth, props.endMonth]);
  
    const getLinkClassName = (linkName) => {
      return (linkName === selectedItem)
        ? "btn btn-primary"
        : "btn btn-light";      
    }

    const buildLink = (linkName, includeStartMonth, includeEndMonth) => {
      let link = "/" + linkName + "?team=";
      link += team ? team : "";

      /*
      if (includeStartMonth && startMonth)
      {
        link += "&startMonth=" + startMonth.toISOString()
      }

      if (includeEndMonth && endMonth)
      {
        link += "&endMonth" + endMonth.toISOString(); 
      }*/
      return link;
    }


    return (    
        <div>
          <div className="row mt-2"></div>
          <div className="row">
            <h1> myneva development reporting <img style={{float:'right'}} src={mynevaLogo} alt="Myneva logo" /></h1>
          </div>
          <div className="row mt-3"></div>
          
            <div className="row">            
              <div className="btn-group" role="group" aria-label="Basic example">
                    <Link to={buildLink("MonthlyDevSummary", true, true)} ><button type="button" className={getLinkClassName("MonthlyDevSummary")}>Dev Summary</button></Link>
                    <Link to={buildLink("MonthlyDevOverview", true, true)} ><button type="button" className={getLinkClassName("MonthlyDevOverview")}>Dev Overview</button></Link>
                    <Link to={buildLink("MonthlyEffortBreakdown", true, true)} ><button type="button" className={getLinkClassName("MonthlyEffortBreakdown")}>Effort breakdown</button></Link>
                    <Link to={buildLink("AdministrativeTaskBreakdown", true, true)} ><button type="button" className={getLinkClassName("AdministrativeTaskBreakdown")}>Administrative task breakdown</button></Link>
                    <Link to={buildLink("MonthlyProductBreakdown", true, true)} ><button type="button" className={getLinkClassName("MonthlyProductBreakdown")}>Product breakdown</button></Link>
                    <Link to={buildLink("MonthlyDoraMetrics", true, false)} ><button type="button" className={getLinkClassName("MonthlyDoraMetrics")}>Dora metrics</button></Link>
                    <Link to={buildLink("TacticalDashboard", true, false)} ><button type="button" className={getLinkClassName("TacticalDashboard")}>Tactical Engineering Dashboard</button></Link>
                    <Link to={buildLink("CapitalisationReport", false, false)} ><button type="button" className={getLinkClassName("CapitalisationReport")}>Capitalization report</button></Link>
              </div>
            </div>
            <div className="row mt-2"></div>
        </div>);
  }