import React from 'react';
import DoraNamespacePicker from '../../ui/DoraNamespacePicker';
import DoraRessourcePicker from '../../ui/DoraRessourcePicker';
import { MonthPicker } from "../../ui/MonthPicker";
import { Form } from 'react-bootstrap';
import {withMsal} from "@azure/msal-react";
import MonthlyDoraMetricsDashboard from "./MonthlyDoraMetricsDashboard";
import {withSearchParams} from "../../extensions/withSearchParams";
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';
import MonthlyDoraMetricsChart from './MonthlyDoraMetricsChart';

class MonthlyDoraMetrics extends React.Component {
    constructor (props) {
        super(props);
        const currentDate = new Date();
        let month = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        let selectedTeam = "myneva.carecenter";
        let selectedResource = "- Summary -";

        let areOptionsVisible = true;
        if (this.props.searchParams)
        {
            month = this.props.searchParams.get("month") ? new Date(this.props.searchParams.get("month")) : month;
            selectedTeam = this.props.searchParams.get("team") || selectedTeam;
            selectedResource = this.props.searchParams.get("selectedResource") || selectedResource;
            if (this.props.searchParams.get("nofilter") === '1')
                areOptionsVisible = false;
        }
        month.setHours(0, -month.getTimezoneOffset(),0,0);

        this.state = {
            month: month,
            selectedTeam: selectedTeam,
            selectedResource: selectedResource,
            areOptionsVisible: areOptionsVisible
        }
    }

    onTeamChange(option) {
        this.setState({
            month: this.state.month,
            selectedTeam: option,
            selectedResource: '- Summary -',
            areOptionsVisible: this.state.areOptionsVisible
        })
    };

    onResourceChange(option) {
        this.setState({
            month: this.state.month,
            selectedTeam: this.state.selectedTeam,
            selectedResource: option,
            areOptionsVisible: this.state.areOptionsVisible
        })
    };

    onStartDateChange(startDate) {
        startDate.setHours(0, -startDate.getTimezoneOffset(),0,0);
        this.setState({
            month: startDate,
            selectedTeam: this.state.selectedTeam,
            selectedResource: this.state.selectedResource,
            areOptionsVisible: this.state.areOptionsVisible
        })
    }

    render() {
        return (
            <div className="container">
                { this.state.areOptionsVisible ?
                    (<div>
                        <ReportNavigationBar selectedItem={'MonthlyDoraMetrics'} startMonth={this.state.month} endMonth={this.state.month}/>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Form.Label>Namespace</Form.Label>
                                    <DoraNamespacePicker onChange={(o) => this.onTeamChange(o)} value={this.state.selectedTeam}/>
                                </div>
                                <div className="col-sm-4">
                                    <Form.Label>Ressource</Form.Label>
                                    <DoraRessourcePicker onChange={(o) => this.onResourceChange(o)} value={this.state.selectedResource} team={this.state.selectedTeam}/>
                                </div>

                                <div className="col-sm-4">
                                    <Form.Label>Target month</Form.Label>
                                    <MonthPicker value={this.state.month} onChange={(o) => this.onStartDateChange(o)} />
                                </div>
                            </div>
                            <div className="row mt-2"></div>
                        </div>
                    ) : <div></div>}
                <div className="card">
                    <div className="row">
                        <MonthlyDoraMetricsChart selectedTeam={this.state.selectedTeam} selectedResource={this.state.selectedResource} startMonth={new Date(this.state.month.getFullYear() - 1, this.state.month.getMonth(), this.state.month.getDay())} endMonth={this.state.month} />
                    </div>
                    <div className="row">
                        <MonthlyDoraMetricsDashboard selectedTeam={this.state.selectedTeam} month={this.state.month} selectedResource={this.state.selectedResource} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withMsal(withSearchParams(MonthlyDoraMetrics));