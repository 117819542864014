import React from 'react';
import Table from 'react-bootstrap/Table'
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";
import { CSVLink } from 'react-csv';

class CurrentRoadmapIssueList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            team: props.team,
            issueList: null,
            sortField: 'riceScore',
            isSortDesc: true,
            status: ""
        }
    }

    fetchData(team) {
        const request = {
            ...apiRequest,
            account: this.props.msalContext.accounts[0]
        };
    
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {
            const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/CurrentRoadmapEpics/" + team
            const bearer = "Bearer " + tokenResponse.accessToken;            
            fetch(url, {
                headers: new Headers({
                    "X-API-KEY": process.env.REACT_APP_API_KEY
                })
            })
            .then(response => response.json()).then(result => {
                this.setState({
                    team: team,
                    issueList: result.issues,
                    sortField: this.state.sortField,
                    isSortDesc: this.state.isSortDesc,
                    status: ""
                })
            })
        })
        .catch(err => {
            console.log(err);
            this.setState({
                team: team,
                issueList: null,
                sortField: this.state.sortField,
                isSortDesc: this.state.isSortDesc,
                status: "Error while loading the issue data"
            });
        })        
    }

    componentDidMount() {
        this.setState({
            team: this.state.team,
            issueList: this.state.issueList,
            sortField: this.state.sortField,
            isSortDesc: this.state.isSortDesc,
            status: "Loading issue detail list"
        });
        this.fetchData(this.state.team);        
    }

    componentDidUpdate(prevProps, prevState) {        
        if (this.props.team !== this.state.team)
            this.fetchData(this.props.team);
    }

    setSortedField(newSortField) {
        const isSortDesc = newSortField === this.state.sortField ? !this.state.isSortDesc : false;
        this.setState({
            team: this.state.team,
            issueList: this.state.issueList,
            sortField: newSortField,
            isSortDesc: isSortDesc,
            status: this.state.status

        })
    }


    render() {
        if (this.state.issueList) {
            let displayList = [...this.state.issueList];
            displayList.sort((a,b) => {
                if (a[this.state.sortField] < b[this.state.sortField]) {
                    return this.state.isSortDesc ? 1 : -1;
                } else if (a[this.state.sortField] > b[this.state.sortField]) {
                    return this.state.isSortDesc ? -1 : 1;
                } else {
                    return 0;
                }                  
            });

            
            const sortSymbol = this.state.isSortDesc 
              ? " 🠣"
              : " 🠡";

            return (
                <div className="table-roadmap">
                <h4 className="text-center">Current roadmap {this.state.team}</h4>
                <CSVLink data={displayList} separator={";"} enclosingCharacter={'"'} filename={"current_roadmap_" + this.state.team + ".csv"}>Download data as csv</CSVLink>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('issueKey')}>Key{this.state.sortField === "issueKey" && sortSymbol}</button></th>
                            <th className='col-sm-4'><button className="btn btn-primary" onClick={() => this.setSortedField('summary')}>Summary{this.state.sortField === "summary" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('plannedStartDate')}>Start date{this.state.sortField === "plannedStartDate" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('plannedDueDate')}>Due date{this.state.sortField === "plannedDueDate" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('riceScore')}>RICE Strategic Score{this.state.sortField === "riceScore" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('riceOpportunityScore')}>RICE Opportunity Score{this.state.sortField === "riceOpportunityScore" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('riceFeatureValue')}>Strategic Value{this.state.sortField === "riceFeatureValue" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('riceOpportunityValue')}>Opportunity Value{this.state.sortField === "riceOpportunityValue" && sortSymbol}</button></th>
                            <th className='col-sm-1'><button className="btn btn-primary" onClick={() => this.setSortedField('riceTotalEstimatedValue')}>Total est. revenue{this.state.sortField === "riceTotalEstimatedValue" && sortSymbol}</button></th>                            
                        </tr>
                    </thead>
                    <tbody>
                        {displayList.map(i => (
                            <tr key={i.issueKey}>
                                <td><a href={i.issueUrl} className="link-dark" target="_blank" rel="noreferrer">{i.issueKey}</a></td>
                                <td>{i.summary}</td>
                                <td>{i.plannedStartDate > '01.01.1900' ? new Date(i.plannedStartDate).toLocaleDateString() : ""}</td>
                                <td>{i.plannedDueDate > '01.01.1900' ? new Date(i.plannedDueDate).toLocaleDateString() : ""}</td>
                                <td className="col-text-right">{i.riceScore.toFixed(2)}</td>
                                <td className="col-text-right">{i.riceOpportunityScore.toFixed(2)}</td>
                                <td className="col-text-right">€{i.riceFeatureValue.toLocaleString()}</td>
                                <td className="col-text-right">€{i.riceOpportunityValue.toLocaleString()}</td>
                                <td className="col-text-right">€{i.riceTotalEstimatedValue.toLocaleString()}</td>                                                      
                            </tr>
                        ))}
                    </tbody>
                </Table>
                </div>
            )
        } else {
            return (
                <div className="alert alert-warning" role="alert">
                      {this.state.status}
                </div>);
        
        }
    }
}

export default withMsal(CurrentRoadmapIssueList);