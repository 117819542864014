import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { apiRequest } from "../../authConfig";
import { withMsal } from "@azure/msal-react";

ChartJS.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);



class MonthlyEffortBreakdownChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: props.team,
      startMonth: props.startMonth,
      endMonth: props.startMonth,
      chartData: null,
      status: '',
      lastDatapointOutput: ''
    }
  }

  localeOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  formatNumber(number) {
    console.log(number);
    if (number === undefined)
      number = 0;
    return number.toLocaleString(undefined, this.localeOptions)
  }  


  fetchData(team, startMonth, endMonth) {
    const request = {
      ...apiRequest,
      account: this.props.msalContext.accounts[0]
    };

    this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {    
      const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/MonthlyEffortBreakdown/" + team + "?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();
      const bearer = "Bearer " + tokenResponse.accessToken;            
      fetch(url, {
          headers: new Headers({
            "X-API-KEY": process.env.REACT_APP_API_KEY
          })
      })
        .then(response => response.json())
        .then(result => {
          let title = "";
          if (startMonth && endMonth)
          {          
            title = "Development breakdown for " + team + " " 
              + (startMonth.getMonth() + 1) + "/" + (startMonth.getFullYear()) + " - "
              + (endMonth.getMonth() + 1) + "/" + (endMonth.getFullYear());
          }

          const lastDatasetIdx = result.labels.length - 1;
          let lastDataOutput = [];

          if (lastDatasetIdx >= 0) {
            result.datasets.forEach(d => {
              lastDataOutput.push({
                label: d.label,
                value: this.formatNumber(d.data[lastDatasetIdx])
              })
            });
            lastDataOutput.pop();
          }

          console.log(result);

          this.setState({
            startMonth: startMonth,
            endMonth: endMonth,
            team: team,
            chartData: {
              title : title,
              datasets: result,
            },
            status: '',
            lastDatapointOutput: lastDataOutput
          });
        })
    })
    .catch(err =>
      {
        console.log(err);
        this.setState({
          startMonth: startMonth,
          endMonth: endMonth,
          team: team,
          chartData: null,
          status: "Error while loading the chart data",
          lastDataOutput: null
        });
      })
  }

  componentDidMount() {    
      this.setState({
        team: this.state.team,
        startMonth: this.state.startMonth,
        endMonth: this.state.endMonth,
        chartData: this.state.chartData,
        status: "Loading effort breakdown chart",
        lastDataOutput: null
      });
  }

  componentDidUpdate(prevProps, prevState) {    
    if (this.props.team !== this.state.team
      || this.props.startMonth !== this.state.startMonth
      || this.props.endMonth !== this.state.endMonth)
      this.fetchData(this.props.team, this.props.startMonth, this.props.endMonth);
  }

  render() {  
    if (this.state.chartData) {
      const chartOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: true
          },
          title: {
            display: true,
            text: this.state.chartData.title,
            color: '#000000',
            font: {
              size: 22,
            }
          },
          datalabels: {
            display: false
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        }
      };

      return (      
        <div>
          <Line options={chartOptions} data={this.state.chartData.datasets} />         
          <div className="row"><h6>Values for {(this.state.endMonth.getMonth() + 1) + " / " + this.state.endMonth.getFullYear()}</h6></div>
          <>            
              {this.state.lastDatapointOutput.map(d => (   
              <div className="row">
              <div className="col-sm-3">{d.label}</div>
              <div className="col-sm-2">{d.value}</div>              
              </div>           
              ))}
          </>          
        </div>                  
      )       
    }
    else {
      return (
        <div className="alert alert-warning" role="alert">
              {this.state.status}
        </div>);
    }

  }
}

export default withMsal(MonthlyEffortBreakdownChart);
