import { 
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"
import React from "react";
import ReactDOM from "react-dom";
import MonthlyDevOverview from "./routes/MonthlyDevOverview/MonthlyDevOverview";
import MonthlyEffortBreakdown from "./routes/MonthlyEffortBreakdown/MonthlyEffortBreakdown";
import MonthlyDevSummary from "./routes/MonthlyDevSummary/MonthlyDevSummary";
import MonthlyProductBreakdown from "./routes/MonthlyProductBreakdown/MonthlyProductBreakdown";
import CurrentRoadmapOverview from "./routes/CurrentRoadmapOverview/CurrentRoadmapOverview"
import TacticalDashboard from "./routes/TacticalDashboard/TacticalDashboard";
import MonthlyDoraMetrics from "./routes/MonthlyDoraMetrics/MonthlyDoraMetrics";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import 'bootstrap/dist/css/bootstrap.min.css';
import { SignInButton } from "./ui/SignInButton";
import './index.css';
import mynevaLogo from './assets/myneva.svg';
import AdministrativeTaskBreakdown from "./routes/AdministrativeTaskBreakdown/AdministrativeTaskBreakdown";
import CapitalisationReport from "./routes/CapitialisationReport/CapitalisationReport";

const msalInstance = new PublicClientApplication(msalConfig);

const rootElement = document.getElementById("root");
ReactDOM.render(    
  <React.StrictMode>  
    <MsalProvider instance={msalInstance}>
    <AuthenticatedTemplate>      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MonthlyDevSummary />} />
          <Route path="monthlydevoverview" element={<MonthlyDevOverview />}  />
          <Route path="monthlydevsummary" element={<MonthlyDevSummary/>} />
          <Route path="administrativetaskbreakdown" element={<AdministrativeTaskBreakdown/>} />
          <Route path="monthlyeffortbreakdown" element={<MonthlyEffortBreakdown/>}   />
          <Route path="monthlyproductbreakdown" element={<MonthlyProductBreakdown/>}   />
          <Route path="monthlydorametrics" element={<MonthlyDoraMetrics/>} />
          <Route path="tacticaldashboard" element={<TacticalDashboard/>} />
          <Route path="capitalisationreport" element={<CapitalisationReport/>} />
        </Routes>    
      </BrowserRouter>
      </AuthenticatedTemplate>           
      <UnauthenticatedTemplate>        
        <BrowserRouter>
          <div>
            
          </div>            
          <div className="alert alert-primary" role="alert">
          <h1> myneva development reporting <img style={{float:'right'}} src={mynevaLogo} alt="Myneva logo" /></h1>
          <br/>
            <SignInButton/>                                
          </div>
        </BrowserRouter>
        
      </UnauthenticatedTemplate>        
    </MsalProvider>
  </React.StrictMode>  ,
  rootElement
);
