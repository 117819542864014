export const msalConfig = {
    auth: {
      clientId: "eed8b072-6d4f-42eb-8259-9794710018bc",
      authority: "https://login.microsoftonline.com/0696ad1c-a986-4600-a72c-7e0a5502930c", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})      
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };

  export const apiRequest = {
    scopes: ["api://eed8b072-6d4f-42eb-8259-9794710018bc/Read.Data"]
   };