import React from 'react';
import CurrentRoadmapIssueList from './CurrentRoadmapIssueList';
import { withSearchParams } from '../../extensions/withSearchParams';
import TeamPicker from "../../ui/TeamPicker";
import { Form } from 'react-bootstrap';
import { withMsal } from "@azure/msal-react";
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';



class CurrentRoadmapOverview extends React.Component {      
    constructor (props) {
        super(props); 
        let selectedTeam = "Linked Care";

        let areOptionsVisible = true;
        if (this.props.searchParams)
        {            
            selectedTeam = this.props.searchParams.get("team") || selectedTeam;                
            if (this.props.searchParams.get("nofilter") === '1')
              areOptionsVisible = false;
        }        

        this.state = {
            selectedTeam: selectedTeam,
            areOptionsVisible: areOptionsVisible
        }        
    }

    onTeamChange(option) {        
        this.setState({
            selectedTeam: option,
            areOptionsVisible: this.state.areOptionsVisible
        })
    };

    
    render() {  
        return (
          <div className="container"> 
          { this.state.areOptionsVisible ?
            (<div>
                <ReportNavigationBar selectedItem={'CurrentRoadmapOverview'} team={this.state.selectedTeam}/>
                <div className="row">
                    <div className="col-sm-12">
                        <Form.Label>Team</Form.Label>
                        <TeamPicker onChange={(o) => this.onTeamChange(o)} value={this.state.selectedTeam}/>
                    </div>
                </div>
                <div className="row mt-2"></div>

                </div>
            
            ) : <div></div>}            
                <div className="row">
                    <CurrentRoadmapIssueList team={this.state.selectedTeam} />
                </div>         
          </div>   
        )
    }
}

export default withMsal(withSearchParams(CurrentRoadmapOverview));

