import React from "react";


export class ColorBlock extends React.Component {  
    constructor (props) {
        super(props);     
    }

  
    render()
    {        
        return (    
            <span style={{color: this.props.color}}>&#9632;</span>  
        )
    }
}