import React from "react";
import { InputGroup } from "react-bootstrap";
import "react-month-picker/css/month-picker.css";

export class MonthPicker extends React.Component {  
    constructor (props) {
        super(props); 
        const currentDate = new Date();
        let yearRange = [];
        for (var i = 2021; i <= currentDate.getFullYear(); i++) {
            yearRange.push(i);
        }
        this.state = {
            yearRange: yearRange
        }        
    }

    onChangeYear = (event) => {
        this.props.onChange(new Date(event.target.value, this.props.value.getMonth(), 1))
    }

    onChangeMonth = (event) => {
        this.props.onChange(new Date(this.props.value.getFullYear(), event.target.value, 1))
    }
    
    render()
    {        
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (    
            <div>
                <InputGroup>
                <select className="form-control" onChange={this.onChangeMonth} value={this.props.value.getMonth()}>
                {months.map((name, i) => {
                    return <option key={i} value={i}>{name}</option>;
                })
                }
                </select>
                <select className="form-control" onChange={this.onChangeYear} value={this.props.value.getFullYear()}>
                {this.state.yearRange.map(v => {
                    return <option key={v} value={v}>{v}</option>;
                })
                }
                </select>   
                </InputGroup>       
            </div>  
        )
    }
}




