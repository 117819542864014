import React from 'react';
import MonthlyEffortBreakdownChart from "./MonthlyEffortBreakdownChart";
import MonthlyEffortBreakdownIssueList from "./MonthlyEffortBreakdownIssueList";
import { withSearchParams } from '../../extensions/withSearchParams';
import TeamPicker from "../../ui/TeamPicker";
import { MonthPicker } from "../../ui/MonthPicker";
import { Form } from 'react-bootstrap';
import { withMsal } from "@azure/msal-react";
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';


class MonthlyEffortBreakdown extends React.Component {      
    constructor (props) {
        super(props); 
            
        const currentDate = new Date();
        let endMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endMonth.setMonth(endMonth.getMonth()-1);        
        let startMonth = new Date(endMonth);
        startMonth.setMonth(startMonth.getMonth()-11);
        let selectedTeam = "Linked Care";

        let areOptionsVisible = true;
        let isTableVisible = true;
        if (this.props.searchParams)
        {            
            startMonth = this.props.searchParams.get("startMonth") ? new Date(this.props.searchParams.get("startMonth")) : startMonth;
            endMonth = this.props.searchParams.get("endMonth") ? new Date(this.props.searchParams.get("endMonth")) : endMonth;
            selectedTeam = this.props.searchParams.get("team") || selectedTeam;                
            if (this.props.searchParams.get("nofilter") === '1')
              areOptionsVisible = false;
              if (this.props.searchParams.get("notable") === '1')
              isTableVisible = false;

        }        
        startMonth.setHours(0, -startMonth.getTimezoneOffset(),0,0);
        endMonth.setHours(0, -endMonth.getTimezoneOffset(),0,0);

        this.state = {
            startMonth: startMonth,
            endMonth: endMonth,
            selectedTeam: selectedTeam,
            areOptionsVisible: areOptionsVisible,
            isTableVisible: isTableVisible
        }        
    }

    onTeamChange(option) {        
        this.setState({
            startMonth: this.state.startMonth,
            endMonth: this.state.endMonth,
            selectedTeam: option,
            areOptionsVisible: this.state.areOptionsVisible,
            isTableVisible: this.state.isTableVisible
        })
    };

    onStartDateChange(startDate) {        
        startDate.setHours(0, -startDate.getTimezoneOffset(),0,0);
        this.setState({
            startMonth: startDate <= this.state.endMonth ? startDate : this.state.endMonth,
            endMonth: startDate <= this.state.endMonth ? this.state.endMonth : startDate,
            selectedTeam: this.state.selectedTeam,
            areOptionsVisible: this.state.areOptionsVisible,
            isTableVisible: this.state.isTableVisible
        })        
    }

    onEndDateChange(endDate) {        
        endDate.setHours(0, -endDate.getTimezoneOffset(),0,0);
        this.setState({
            startMonth: endDate >= this.state.startMonth ? this.state.startMonth : endDate,
            endMonth: endDate >= this.state.startMonth ? endDate : this.state.startMonth,
            selectedTeam: this.state.selectedTeam,
            areOptionsVisible: this.state.areOptionsVisible,
            isTableVisible: this.state.isTableVisible
        })
    
    }
    
    render() {  
        return (
          <div className="container"> 
          { this.state.areOptionsVisible ?
            (<div>
                <ReportNavigationBar selectedItem={'MonthlyEffortBreakdown'} team={this.state.selectedTeam} startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>
                <div className="row">
                    <div className="col-sm-4">
                        <Form.Label>Product</Form.Label>
                        <TeamPicker onChange={(o) => this.onTeamChange(o)} value={this.state.selectedTeam}/>
                    </div>
                    <div className="col-sm-4">
                        <Form.Label>Start month</Form.Label>
                        <MonthPicker value={this.state.startMonth} onChange={(o) => this.onStartDateChange(o)} />
                    </div>
                    <div className="col-sm-4">
                        <Form.Label>End month</Form.Label>
                        <MonthPicker value={this.state.endMonth} onChange={(o) => this.onEndDateChange(o)} />
                    </div> 
                </div>
                <div className="row mt-2"></div>

                </div>
            
            ) : <div></div>}
            <div className='card'>
                <div className="row">
                    <MonthlyEffortBreakdownChart team={this.state.selectedTeam} startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>
                </div>
            {this.state.isTableVisible ? 
                (<div><div className="row mt-5"></div>
                <div className="row">
                <MonthlyEffortBreakdownIssueList team={this.state.selectedTeam} month={this.state.endMonth}/>            
                </div></div>)  : <div></div>}            
          </div>   
          </div>            
        )
    }
}

export default withMsal(withSearchParams(MonthlyEffortBreakdown));

