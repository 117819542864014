import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';
  import { Bar } from "react-chartjs-2";
  import ChartDataLabels from 'chartjs-plugin-datalabels';  
  import React from 'react';
  import { withMsal } from "@azure/msal-react";
  import { apiRequest } from "../../authConfig";

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  class MonthlyProductBreakdownChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          team: props.team,
          startMonth: props.startMonth,
          endMonth: props.endMonth,
          chartData: null,
          status: ""
        }
      }
    
      fetchData(team, startMonth, endMonth) {
        const request = {
          ...apiRequest,
          account: this.props.msalContext.accounts[0]
        };
  
        this.props.msalContext.instance.acquireTokenSilent(request).then(tokenResponse => {          
          const bearer = "Bearer " + tokenResponse.accessToken;       
          const url = process.env.REACT_APP_API_BASE_URL + "/GroupReporting/ProductBreakdown/" + team + "?startMonth=" + startMonth.toISOString() + "&endMonth=" + endMonth.toISOString();    
          fetch(url, {
            headers: new Headers({
              "X-API-KEY": process.env.REACT_APP_API_KEY
            })
          })
            .then(response => response.json())
            .then(result => {
              let title = "";
              if (startMonth && endMonth)
              {          
                if (startMonth === endMonth)
                  title = "Product breakdown for " + team + " " 
                  + (startMonth.getMonth() + 1) + "/" + (startMonth.getFullYear());
                else
                  title = "Product breakdown for " + team + " " 
                  + (startMonth.getMonth() + 1) + "/" + (startMonth.getFullYear()) + " - "
                  + (endMonth.getMonth() + 1) + "/" + (endMonth.getFullYear());
              }
      
              this.setState({
                startMonth: startMonth,
                endMonth: endMonth,
                team: team,
                chartData: {
                  title : title,
                  datasets: result,
                },
                status: ''
              });
            })
        })
        .catch(err =>
          {
            console.log(err);
            this.setState({
              startMonth: startMonth,
              endMonth: endMonth,             
              team: team,
              chartData: null,
              status: "Error while loading the chart data"
            });
          })
      }
    
      componentDidMount() {
          this.setState({
            team: this.state.team,
            startMonth: this.state.startMonth,
            endMonth: this.state.endMonth,
            chartData: this.state.chartData,
            status: "Loading product breakdown chart"
          });
          this.fetchData(this.state.team, this.state.startMonth, this.state.endMonth);    
      }
    
      componentDidUpdate(prevProps, prevState) {    
        if (this.props.team !== this.state.team
          || this.props.startMonth !== this.state.startMonth
          || this.props.endMonth !== this.state.endMonth)
          this.fetchData(this.props.team, this.props.startMonth, this.props.endMonth);
      }
    
      render() {
        if (this.state.chartData) {
          const chartOptions = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                display: false
              },
              title: {
                  display: true,
                  text: this.state.chartData.title,
                  color: '#000000',
                  font: {
                  size: 22,
                  }
              },
              datalabels: {
                color: 'black',
                anchor: 'end',
                align: 'end',
                formatter: function(value, context) {
                  return (value[0]-value[1]).toFixed(2);
                }
              }        
            },
            hover: {
              mode: 'nearest',
              intersect: true    
            }
          };
      
    
          return (      
            <Bar options={chartOptions} data={this.state.chartData.datasets} plugins={[ChartDataLabels]}/> 
          )       
        }
        else {
          return (
            <div className="alert alert-warning" role="alert">
                  {this.state.status}
            </div>);
            }
    
      }
    }

    export default withMsal(MonthlyProductBreakdownChart);
    