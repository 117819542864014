import React from 'react';
import { withSearchParams } from '../../extensions/withSearchParams';
import TeamPicker from "../../ui/TeamPicker";
import { MonthPicker } from "../../ui/MonthPicker";
import { Form } from 'react-bootstrap';
import { ReportNavigationBar } from '../../ui/ReportNavigationBar';
import AdministrativeTaskBreakdownPie from './AdministrativeTaskBreakdownPie';
import AdministrativeTaskBreakdownTable from './AdministrativeTaskBreakdownTable';

class AdministrativeTaskBreakdown extends React.Component {  
    constructor (props) {
        super(props); 
        const currentDate = new Date();
        let endMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endMonth.setMonth(endMonth.getMonth()-1);        
        let startMonth = new Date(endMonth);
        startMonth.setMonth(startMonth.getMonth()-11);
        let selectedTeam = "Linked Care";

        let areOptionsVisible = true;
        if (this.props.searchParams)
        {            
            startMonth = this.props.searchParams.get("startMonth") ? new Date(this.props.searchParams.get("startMonth")) : startMonth;
            endMonth = this.props.searchParams.get("endMonth") ? new Date(this.props.searchParams.get("endMonth")) : endMonth;
            selectedTeam = this.props.searchParams.get("team") || selectedTeam;                
            if (this.props.searchParams.get("nofilter") === '1')
              areOptionsVisible = false;
        }        
        startMonth.setHours(0, -startMonth.getTimezoneOffset(),0,0);
        endMonth.setHours(0, -endMonth.getTimezoneOffset(),0,0);

        this.state = {
            startMonth: startMonth,
            endMonth: endMonth,
            selectedTeam: selectedTeam,
            areOptionsVisible: areOptionsVisible
        }        
    }

    onTeamChange(option) {        
        this.setState({
            startMonth: this.state.startMonth,
            endMonth: this.state.endMonth,
            selectedTeam: option,
            areOptionsVisible: this.state.areOptionsVisible
        })
    };

    onStartDateChange(startDate) {        
        startDate.setHours(0, -startDate.getTimezoneOffset(),0,0);
        this.setState({
            startMonth: startDate <= this.state.endMonth ? startDate : this.state.endMonth,
            endMonth: startDate <= this.state.endMonth ? this.state.endMonth : startDate,
            selectedTeam: this.state.selectedTeam,
            areOptionsVisible: this.state.areOptionsVisible
        })        
    }

    onEndDateChange(endDate) {        
        endDate.setHours(0, -endDate.getTimezoneOffset(),0,0);
        this.setState({
            startMonth: endDate >= this.state.startMonth ? this.state.startMonth : endDate,
            endMonth: endDate >= this.state.startMonth ? endDate : this.state.startMonth,
            selectedTeam: this.state.selectedTeam,
            areOptionsVisible: this.state.areOptionsVisible
        })
    
    }
    
    render() {  

        return (
          <div className="container"> 
          { this.state.areOptionsVisible ?
            (<div>
                <ReportNavigationBar selectedItem={'AdministrativeTaskBreakdown'} team={this.state.selectedTeam} startMonth={this.state.startMonth} endMonth={this.state.endMonth}/>                
            <div className="row">
                <div className="col-sm-4">
                    <Form.Label>Product</Form.Label>
                    <TeamPicker onChange={(o) => this.onTeamChange(o)} value={this.state.selectedTeam}/>
                </div>
                <div className="col-sm-4">
                    <Form.Label>Start month</Form.Label>
                    <MonthPicker value={this.state.startMonth} onChange={(o) => this.onStartDateChange(o)} />
                </div>
                <div className="col-sm-4">
                    <Form.Label>End month</Form.Label>
                    <MonthPicker value={this.state.endMonth} onChange={(o) => this.onEndDateChange(o)} />
                </div> 
            </div>            
            <div className="row mt-2"></div>
            </div>
            ) : <div></div>}
            <div className="card">
            <div className="row">
                <div className="col-sm-6">
                    <AdministrativeTaskBreakdownPie team={this.state.selectedTeam} startMonth={this.state.endMonth} endMonth={this.state.endMonth} />                
                </div>
                <div className="col-sm-6">
                    <AdministrativeTaskBreakdownPie team={this.state.selectedTeam} startMonth={this.state.startMonth} endMonth={this.state.endMonth} />                
                </div>
            </div>
            <div className="row mt-5"></div>
            <div className="row">
                <div className="col-sm-6">
                    <AdministrativeTaskBreakdownTable team={this.state.selectedTeam} startMonth={this.state.endMonth} endMonth={this.state.endMonth} />                
                </div>
                <div className="col-sm-6">
                    <AdministrativeTaskBreakdownTable team={this.state.selectedTeam} startMonth={this.state.startMonth} endMonth={this.state.endMonth} />                
                </div>
            </div>
            </div>
          </div>   
        )
    }
}

export default withSearchParams(AdministrativeTaskBreakdown);




